import * as React from "react"

import {
  SiReact,
  SiTypescript,
  SiRedux,
  SiNextDotJs,
  SiGatsby,
  SiTailwindcss,
  SiStyledComponents,
  SiBootstrap,
  SiMongodb,
  SiFirebase,
  SiSass,
  SiGmail,
  SiGithub,
  SiLinkedin,
  SiJavascript,
  SiHtml5,
  SiCss3,
  FiFileText,
} from "./ReactIcons"

function ButtonTech({ title, tech, _callback, techQuery }) {
  const techs = {
    nextjs: <SiNextDotJs style={{ color: "#000000" }} />,
    gatsbyjs: <SiGatsby style={{ color: "#663399" }} />,
    react: <SiReact style={{ color: "#61DAFB" }} />,
    styledcomponents: <SiStyledComponents style={{ color: "#DB7093" }} />,
    firebase: <SiFirebase style={{ color: "#FFCA28" }} />,
    typescript: <SiTypescript style={{ color: "#3178C6" }} />,
    sass: <SiSass style={{ color: "#CC6699" }} />,
    bootstrap: <SiBootstrap style={{ color: "#7952B3" }} />,
    mongoDB: <SiMongodb style={{ color: "#47A248" }} />,
    tailwindCSS: <SiTailwindcss style={{ color: "#06B6D4" }} />,
    redux: <SiRedux style={{ color: "#764ABC" }} />,
  }

  const pickedTech = techs[tech]

  return (
    <button
      onClick={() => _callback(techQuery)}
      className="capitalize mx-1 my-2 px-1 sm:mx-2 sm:my-2 sm:px-2 rounded-full flex items-center shadow-xl hover:bg-gray-200 duration-200"
    >
      <span className="text-xl sm:text-2xl lg:text-3xl">{pickedTech}</span>
      <span className="p-3 text-md sm:text-lg lg:text-xl text-green-900">
        {title}
      </span>
    </button>
  )
}

function TechIcon({ tech, className }) {
  const techs = {
    nextjs: <SiNextDotJs style={{ color: "#000000" }} />,
    gatsbyjs: <SiGatsby style={{ color: "#663399" }} />,
    react: <SiReact style={{ color: "#61DAFB" }} />,
    "styled components": <SiStyledComponents style={{ color: "#DB7093" }} />,
    firebase: <SiFirebase style={{ color: "#FFCA28" }} />,
    typescript: <SiTypescript style={{ color: "#3178C6" }} />,
    sass: <SiSass style={{ color: "#CC6699" }} />,
    bootstrap: <SiBootstrap style={{ color: "#7952B3" }} />,
    mongoDB: <SiMongodb style={{ color: "#47A248" }} />,
    "tailwind CSS": <SiTailwindcss style={{ color: "#06B6D4" }} />,
    redux: <SiRedux style={{ color: "#764ABC" }} />,
    gmail: <SiGmail style={{ color: "#EA4335" }} />,
    github: <SiGithub style={{ color: "#181717" }} />,
    linkedin: <SiLinkedin style={{ color: "#0A66C2" }} />,
    javascript: <SiJavascript style={{ color: "#F7DF1E" }} />,
    copywriter: <FiFileText style={{ color: "#314033" }} />,
    html: <SiHtml5 style={{ color: "#E34F26" }} />,
    css: <SiCss3 style={{ color: "#1572B6" }} />,
  }

  const pickedTech = techs[tech]

  return (
    <span
      className={
        "text-xl sm:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl " + className
      }
    >
      {pickedTech}
    </span>
  )
}

export { ButtonTech, TechIcon }
