import * as React from "react"

import { TechIcon } from "./Tech"

export function SectionHeader({ children, className }) {
  return (
    <h3
      className={
        "text-2xl lg:text-3xl text-green-900 pt-6 pb-5 font-semibold tracking-wide lg:tracking-wider text-center " +
        className
      }
    >
      {children}
    </h3>
  )
}

export function SectionCard({ children, className }) {
  return (
    <section className={"p-5 shadow-card " + className}>{children}</section>
  )
}

export function SectionContent({ children, className }) {
  return <section className={"p-5 lg:px-10 " + className}>{children}</section>
}

export function SectionTitle({ children, className }) {
  return (
    <h4
      className={
        "text-lg lg:text-xl pb-3 lg:pb-5 font-semibold text-green-900 tracking-wide " +
        className
      }
    >
      {children}
    </h4>
  )
}

export function SectionParagraph({ children, className }) {
  return (
    <p
      className={
        "text-md lg:text-lg py-3 text-green-900 leading-relaxed " + className
      }
    >
      {children}
    </p>
  )
}

export function SectionContact({ tech, title, desc, url }) {
  if (tech === "gmail") {
    return (
      <section className="flex flex-col items-center mx-8 sm:mx-12 md:mx-14 lg:mx-12 my-6 md:my-10">
        <span className="rounded-full shadow-card p-3 hover:bg-gray-200">
          <a href="mailto:syawaljasira@gmail.com" target="_blank">
            <TechIcon tech={tech} />
          </a>
        </span>
        <a
          className="text-lg font-semibold text-green-900"
          href="mailto:syawaljasira@gmail.com"
        >
          {title}
        </a>
        <h6 className="text-md text-green-900">
          <em>{desc}</em>
        </h6>
      </section>
    )
  }

  return (
    <section className="flex flex-col items-center mx-8 sm:mx-12 md:mx-14 my-6 md:my-10">
      <span className="rounded-full shadow-card p-3 hover:bg-gray-200">
        <a href={url} target="_blank">
          <TechIcon tech={tech} />
        </a>
      </span>
      <a href={url}>
        <h6 className="text-lg lg:text-xl text-green-900 font-semibold">
          {title}
        </h6>
      </a>
      <h6 className="text-md lg:text-lg text-green-900">
        <em>{desc}</em>
      </h6>
    </section>
  )
}

export function SectionSkill({ tech, title, desc }) {
  return (
    <section className="flex flex-col items-center mx-5 my-6 sm:mx-10 md:mx-14 lg:mx-16 2xl:mx-20 lg:p-2">
      <span className="rounded-full shadow-card p-3 hover:bg-gray-200">
        <TechIcon tech={tech} />
      </span>
      <h6 className="text-md sm:text-lg 2xl:text-xl text-green-900 font-semibold">
        {title}
      </h6>
      <p className="text-md 2xl:text-lg text-green-900 ">{desc}</p>
    </section>
  )
}
